body {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;

  @screen sm {
    font-size: 14px;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
}

.custom-container {
  max-width: 1250px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
