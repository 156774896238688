.main-loading-element {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  z-index: 2147483647;
  border-radius: 1px;
  will-change: width, opacity;
  transition: width 500ms ease-out, opacity 500ms linear;
  opacity: 0;

  &.loading {
    transition: width 500ms linear;
    opacity: 1;
  }

  &::after,
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    height: 2px;
    box-shadow: 1px 0 6px 1px;
    border-radius: 100%;
    animation: pulse 2s ease-out 0s infinite;
  }

  &::after {
    width: 20px;
    opacity: 0.6;
    right: 0;
    clip: rect(-6px, 30px, 14px, 10px);
  }

  &::before {
    opacity: 0.6;
    width: 180px;
    right: -80px;
    clip: rect(-6px, 90px, 14px, -6px);
  }
}

@keyframes pulse {
  30% {
    opacity: 0.6;
  }

  60% {
    opacity: 0;
  }

  to {
    opacity: 0.6;
  }
}
