$white: #fff;
$black: #000;
$gray-dark: #606060;
$gray: #f2f2f2;
$gray-light: #e1e1e1;
$main-color: #ecc23c;
$secondary-color: #2d2d2d;

$xs: 600px;
$sm: 960px;
$md: 1280px;
$lg: 1440px;
$xl: 1920px;

$error-red: #ec3c3c;

// *** tobiasahlin/SpinKit http://tobiasahlin.com/spinkit/
$circle-count: 12;
$animation-duration: 1.2s;
$spinkit-spinner-margin: auto !default;
$spinkit-size: 70px !default;
$spinkit-spinner-color: $main-color !default;
