@mixin font($family, $size) {
  font-family: $family;
  font-size: $size;
}

@mixin font-w($family, $weight, $size) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
}

@mixin font-i($family, $size) {
  font-family: $family;
  font-size: $size;
  font-style: italic;
}

@mixin bg-cover($size) {
  background-size: $size;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin border-bottom($size, $color, $padding) {
  border-bottom: $size solid $color;
  padding-bottom: $padding;
}

@mixin backdrop($color, $opacity) {
  position: relative;
  z-index: 1;

  &::after {
    background: rgba($color, $opacity);
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

@mixin respond($media) {
  @if $media == 'xs' {
    @media only screen and (max-width: $xs - 1px) {
      @content;
    }
  } @else if $media == 'gt-xs' {
    @media only screen and (min-width: $xs) {
      @content;
    }
  } @else if $media == 'sm' {
    @media only screen and (min-width: $xs) and (max-width: $sm - 1px) {
      @content;
    }
  } @else if $media == 'gt-sm' {
    @media only screen and (min-width: $sm) {
      @content;
    }
  } @else if $media == 'md' {
    @media only screen and (min-width: $sm) and (max-width: $md - 1px) {
      @content;
    }
  } @else if $media == 'gt-md' {
    @media only screen and (min-width: $md) {
      @content;
    }
  } @else if $media == 'lg' {
    @media only screen and (min-width: $md) and (max-width: $lg - 1px) {
      @content;
    }
  } @else if $media == 'gt-lg' {
    @media only screen and (min-width: $lg) {
      @content;
    }
  } @else if $media == 'xl' {
    @media only screen and (min-width: $lg) {
      @content;
    }
  }
}
