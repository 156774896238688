.rte {
  width: 100%;

  h1 {
    font-size: 42px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 32px;
    margin-bottom: 15px;
    border: 0;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 17px;
  }

  h6 {
    font-size: 17px;
    font-weight: bold;
  }

  p,
  ul,
  pre {
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 20px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;
    margin-left: 40px;
  }

  li + li {
    margin-top: 5px;
  }

  li {
    letter-spacing: 0;
    line-height: 25px;

    &::marker {
      font-size: 18px;
    }
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  pre {
    max-width: 100%;
    white-space: normal;
  }

  blockquote {
    text-align: center;
    font-size: 24px;
    padding: 40px 10px;

    p {
      font-size: 24px;
    }
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
    margin: 20px auto;
  }
}
