// http://transfonter.org/
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Light.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/OpenSans-Light.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Light.woff') format('woff'),
    url('/assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/OpenSans.woff2') format('woff2'),
    url('/assets/fonts/OpenSans.woff') format('woff'),
    url('/assets/fonts/OpenSans.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Italic.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/OpenSans-Italic.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Italic.woff') format('woff'),
    url('/assets/fonts/OpenSans-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/OpenSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/OpenSans-Bold.woff') format('woff'),
    url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
