html {
  --webkit-print-color-adjust: exact;
}

@media print {
  @page {
    // https://stackoverflow.com/questions/50549663/scale-html-to-fit-one-page-when-printing
    size: A4 portrait;
    margin: 0;
  }

  .dn--print {
    display: none !important;
  }

  .db--print {
    display: block !important;
  }

  html {
    transform-origin: 0 0;
  }

  .no-page-break,
  img {
    page-break-inside: avoid;
  }

  .page-break-after {
    page-break-after: always;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
}
